import { template as template_e958fa80855e495ca6dd7f5e21e559d5 } from "@ember/template-compiler";
const FKText = template_e958fa80855e495ca6dd7f5e21e559d5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
