import { template as template_7ed44047ea53482a8f9a6175458ae6fe } from "@ember/template-compiler";
const FKControlMenuContainer = template_7ed44047ea53482a8f9a6175458ae6fe(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
